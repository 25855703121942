@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

@import url("./csv_import.css");

:root {
  --green: #15803d;
  --green-dark: #166534;
  --gray-slightly-light: #f3f4f6;
  --gray-light: #e5e7eb;
  --gray-dark: #374151;
  --gray-darkest: #111827;

  --timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

html {
  scroll-behavior: smooth;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Inter", sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }

  body {
    color: white;
    background: black;
  }
}

::-webkit-datetime-edit {
  padding: 0.5rem;
}

::-webkit-datetime-edit-text {
  padding: 0 0.3rem;
}

input[type="date"] {
  background: transparent;
  /* color: yellow; */
}

input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  left: 12px;
  height: 20px;
  width: 20px;
  color: white;
  /* background-color: green; */
  background-image: url("https://cdn-icons-png.flaticon.com/512/747/747310.png");
}

.ag-row-selected {
  z-index: 900 !important;
}
.ag-selection-checkbox {
  height: 100% !important;
}

.slick-style > .slick-list > .slick-track {
  padding-right: 10px;
}

/* ::-webkit-scrollbar {
  display: none;
} */

/*  TODO: Move to module CSS, this is only for deal page so doesn't belong here */

.deal-image-slider .slick-list .slick-track {
  @apply h-[150px] flex space-x-[26px] justify-start py-2 px-1;
}
.deal-image-slider .slick-list .slick-track .slick-slide {
  @apply h-full flex w-auto justify-between !important;
}
.deal-image-slider .slick-list .slick-track .slick-current .slide {
  @apply ring-green-700 h-[134px]
    cursor-pointer rounded-md ring-2 ring-offset-2 !important;
}

.custom-slick > .slick-list {
  height: 100% !important;
}
.custom-slick > .slick-list > .slick-track {
  @apply inline-flex gap-2 md:gap-3 lg:gap-[11px] my-0 mx-auto opacity-100 w-full;
}

.react-player__preview {
  border-radius: 8px !important;
}

.image-slider > .slick-list > .slick-track > .slick-slide > div {
  @apply rounded-lg;
}
.image-slider > .slick-list > .slick-track > .slick-current .slide {
  @apply rounded-lg;
}

.react-tour-content-text-style {
  @apply text-gray-700 !important;
}

.ag-header-cell-label {
  white-space: normal;
  word-wrap: break-word;
}

.rpv-core__viewer {
  border-radius: 8px;
  overflow: hidden;
}

.rpv-core__icon {
  color: white;
  margin-left: -5px;
}

.rpv-core__tooltip-body {
  display: none;
}
#slideshowAnim img {
  background-color: #fff;
}
@media screen and (min-width: 768px) {
  #slideshowAnim .scroll-container.indiana-scroll-container img {
    width: 80px;
    height: 80px;
  }
}

#calendly-root-white-bg .calendly-popup {
  background-color: white;
}

@layer utilities {
  .scrollbar::-webkit-scrollbar {
    @apply w-2 h-full;
  }
  .scrollbar::-webkit-scrollbar-track {
    @apply bg-gray-200;
  }
  .scrollbar::-webkit-scrollbar-thumb {
    @apply bg-gray-500 border-2;
  }
  .scrollbar::-webkit-scrollbar-thumb:hover {
    @apply bg-gray-600;
  }

  .break-all-word {
    word-break: break-word;
  }
}
