.CSVImporter_FileSelector {
  opacity: 0;
  position: relative;
  z-index: 1;
}
.CSVImporter_FileSelector span {
  display: none;
}
.CSVImporter_ImporterFrame__header {
  display: none;
}
.CSVImporter_FileSelector {
  background-color: var(--gray-slightly-light);
  height: 152px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #d1d5db;
  border-radius: 8px;
  transition: background-color var(--timing-function) 150ms;
}
.CSVImporter_FileSelector:hover {
  background-color: var(--gray-light);
}

.CSVImporter_IconButton {
  width: 12px;
  height: 12px;
  background: url("../../../public/icons/chevron-light.svg");
  background-repeat: no-repeat;
  background-size: cover;
  transform: rotate(180deg);
}
.CSVImporter_IconButton::before {
  position: absolute;
  content: "Previous step";
  white-space: nowrap;
  right: 18px;
  top: -4px;
  font-size: 14px;
  transform: rotate(-180deg);
  transition: color var(--timing-function) 150ms;
}
.CSVImporter_IconButton:hover {
  color: var(--green);
}
.CSVImporter_FileStep__mainResultBlock,
.CSVImporter_ImporterFrame {
  background-color: white;
  position: relative;
  z-index: 10;
}
.CSVImporter_ImporterFrame__headerTitle {
  display: none;
}
.CSVImporter_FileStep__header {
  display: flex;
  align-items: center;
  column-gap: 16px;
  color: #4b5563;
  font-size: 16px;
  font-weight: 500;
}
.CSVImporter_FormatRawPreview {
  background-color: var(--gray-slightly-light);
  border: 1px solid var(--gray-light);
  border-radius: 6px;
  padding: 12px;
  margin-top: 8px;
}
.CSVImporter_FormatRawPreview__scroll {
  display: flex;
  flex-direction: column;
  row-gap: 2px;
  height: 100%;
  max-height: 128px;
  overflow-y: auto;
}
.CSVImporter_FormatRawPreview__scroll::-webkit-scrollbar {
  width: 8px;
  height: 100%;
}
.CSVImporter_FormatRawPreview__scroll::-webkit-scrollbar-track {
  background-color: #e5e7eb;
}
.CSVImporter_FormatRawPreview__scroll::-webkit-scrollbar-thumb {
  background-color: #6b7280;
}
.CSVImporter_FormatRawPreview__scroll::-webkit-scrollbar-thumb:hover {
  background-color: #4b5563;
}
.CSVImporter_FormatRawPreview__pre {
  font-size: 14px;
  width: 100%;
  max-width: 650px;
  display: -webkit-box;
  text-overflow: ellipsis;
}
.CSVImporter_ImporterFrame__headerCrumbSeparator {
  display: none;
}
.CSVImporter_FileStep__header:not(:first-child) {
  margin-top: 20px;
}
.CSVImporter_ImporterFrame__headerSubtitle {
  column-gap: 16px;
  color: #4b5563;
  font-size: 16px;
  font-weight: 500;
  margin-top: 24px;
}
.CSVImporter_ImporterFrame__headerSubtitle[tabindex="-1"] {
  display: none;
}
.CSVImporter_FileStep__headerToggle {
  display: flex;
  align-items: center;
  column-gap: 10px;
  cursor: pointer;
}
.CSVImporter_FileStep__headerToggle > input {
  width: 16px;
  height: 16px;
  cursor: pointer;
  background-color: white;
  border: 1px solid var(--green);
  border-radius: 4px;
}
.CSVImporter_FileStep__headerToggle > input[type="checkbox"]:checked,
.CSVImporter_FileStep__headerToggle > input[type="checkbox"]:checked:hover {
  background-color: var(--green);
}
.CSVImporter_FileStep__headerToggle > input[type="checkbox"]:focus {
  outline: 2px solid var(--green);
}
.CSVImporter_FileStep__headerToggle > span {
  color: var(--gray-dark);
  font-size: 14px;
  font-weight: 500;
}
.CSVImporter_FormatDataRowPreview {
  border: 1px solid var(--gray-light);
  border-radius: 8px;
  margin-top: 12px;
  overflow-x: auto;
}
.CSVImporter_FormatDataRowPreview__table {
  width: 100%;
}
.CSVImporter_FormatDataRowPreview__table tr {
  display: flex;
}
.CSVImporter_FormatDataRowPreview__table th:not(:last-child) {
  border-right: 1px solid var(--gray-light);
}
.CSVImporter_FormatDataRowPreview__table th {
  padding: 8px 16px;
  color: var(--gray-darkest);
  font-size: 12px;
  font-weight: 600;
  background-color: var(--gray-slightly-light);
  text-align: left;
  min-width: 175px;
}
.CSVImporter_FormatDataRowPreview__table th:first-child {
  min-width: 179px;
}
.CSVImporter_FormatDataRowPreview__table tbody {
  display: block;
  height: 100%;
  max-height: 352px;
  overflow-y: auto;
}
.CSVImporter_FormatDataRowPreview__table td {
  padding: 8px 16px;
  color: var(--gray-darkest);
  font-size: 12px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  min-width: 175px;
}
.CSVImporter_FormatDataRowPreview__table td:first-child {
  min-width: 179px;
}
.CSVImporter_FormatDataRowPreview__table td:not(:last-child) {
  border-right: 1px solid var(--gray-light);
}
.CSVImporter_ImporterFrame__footer {
  display: flex;
  align-items: center;
  column-gap: 20px;
  margin-top: 24px;
}
.CSVImporter_ImporterFrame__footerFill {
  flex: 1 1 0;
}
.CSVImporter_ImporterFrame__footerError {
  flex: none;
  line-height: 0.8;
  color: red;
  word-break: break-word;
}
.CSVImporter_TextButton[type="button"] {
  color: white;
  font-size: 14px;
  font-weight: 600;
  padding: 8px 12px;
  border-radius: 6px;
  background-color: var(--green);
  transition: background-color var(--timing-function) 150ms;
}
.CSVImporter_TextButton:hover {
  background-color: var(--green-dark);
}

.CSVImporter_ColumnDragSourceArea__pageIndicator {
  display: none;
}
.CSVImporter_ColumnDragSourceArea__page {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(168px, 1fr));
  grid-template-rows: repeat(1, 208px);
  justify-content: space-between;
  grid-auto-flow: column;
  column-gap: 12px;
  overflow-x: auto;
  padding-bottom: 6px;
}
.CSVImporter_ColumnDragSourceArea__page::-webkit-scrollbar {
  width: 100%;
  height: 8px;
}
.CSVImporter_ColumnDragSourceArea__page::-webkit-scrollbar-track {
  background-color: #e5e7eb;
}
.CSVImporter_ColumnDragSourceArea__page::-webkit-scrollbar-thumb {
  background-color: #6b7280;
}
.CSVImporter_ColumnDragSourceArea__page::-webkit-scrollbar-thumb:hover {
  background-color: #4b5563;
}
.CSVImporter_ColumnDragSourceArea__pageFiller {
  display: none;
}
.CSVImporter_ColumnDragSourceArea__control {
  display: none;
}
.CSVImporter_ColumnDragCard {
  border: 1px solid var(--gray-light);
  border-radius: 8px;
  overflow: hidden;
  height: 208px;
  overflow-y: auto;
  user-select: none;
  cursor: grab;
}
.CSVImporter_ColumnDragCard:hover {
  border-color: var(--gray-darkest);
}
.CSVImporter_ColumnDragCard[data-shadow="true"] {
  opacity: 0.4;
}
.CSVImporter_ColumnDragCard__cardHeader {
  background-color: var(--gray-slightly-light);
  color: var(--gray-darkest);
  font-weight: 600;
  font-size: 12px;
  padding: 8px 16px;
  text-align: center;
  border-bottom: 1px solid var(--gray-light);
}
.CSVImporter_ColumnDragCard__cardHeader var {
  display: none;
}
.CSVImporter_ColumnDragCard__cardValue[data-header="true"] {
  color: var(--gray-darkest);
  font-size: 12px;
  font-weight: 600;
  padding: 8px;
  border-bottom: 1px solid var(--gray-light);
}
.CSVImporter_ColumnDragSourceArea__box {
  min-width: 168px;
  position: relative;
}
.CSVImporter_ColumnDragCard__cardValue {
  color: var(--gray-darkest);
  font-size: 12px;
  padding: 8px;
}
.CSVImporter_ColumnDragTargetArea {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  justify-content: space-between;
  column-gap: 12px;
  margin-top: 84px;
  position: relative;
}
.CSVImporter_ColumnDragTargetArea:after {
  position: absolute;
  content: "Drag & Drop to set the order";
  top: -70px;
  left: 0;
  color: white;
  font-size: 14px;
  font-weight: 500;
  background-color: var(--gray-darkest);
  border-radius: 8px;
  padding: 8px 12px;
  max-width: 152px;
}
.CSVImporter_ColumnDragTargetArea__boxValue {
  background: var(--gray-slightly-light);
  border: 1px solid var(--gray-light);
  border-radius: 8px;
  overflow: hidden;
  position: relative;
}
.CSVImporter_ColumnDragTargetArea__boxPlaceholderHelp {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 0.5em;
  text-align: center;
  color: #6b7280;
  text-align: center;
  border-radius: 8px;
  background-color: var(--gray-slightly-light);
  cursor: default;
  user-select: none;
}
.CSVImporter_ColumnDragSourceArea__boxAction {
  position: absolute;
  top: 1px;
  right: 14px;
}
.CSVImporter_ColumnDragSourceArea__boxAction .CSVImporter_IconButton {
  width: 16px;
  height: 16px;
  background: url("../../../public/icons/arrows_rotate.svg");
}
.CSVImporter_ColumnDragSourceArea__boxAction .CSVImporter_IconButton::before {
  display: none;
}
.CSVImporter_ColumnDragTargetArea__boxLabel {
  color: var(--gray-darkest);
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 12px;
  cursor: default;
  user-select: none;
}
.CSVImporter_ColumnDragTargetArea__boxValueAction {
  position: absolute;
  top: 1px;
  right: 14px;
}
.CSVImporter_ColumnDragTargetArea__boxValueAction .CSVImporter_IconButton {
  width: 16px;
  height: 16px;
  background: url("../../../public/icons/close.svg");
}
.CSVImporter_ColumnDragTargetArea__boxValueAction
  .CSVImporter_IconButton::before {
  display: none;
}

.CSVImporter_ProgressDisplay {
  border: 1px solid var(--gray-light);
  border-radius: 8px;
  padding: 12px;
  padding-left: 64px;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  position: relative;
}
.CSVImporter_ProgressDisplay__count var {
  font-style: normal;
}
.CSVImporter_ProgressDisplay__progressBar {
  height: 8px;
  background-color: var(--gray-slightly-light);
  border-radius: 100px;
}
.CSVImporter_ProgressDisplay__progressBar::before {
  position: absolute;
  content: "";
  left: 12px;
  bottom: 12px;
  width: 40px;
  height: 40px;
  background-image: url("../../../public/icons/csv.svg");
  background-repeat: no-repeat;
  background-size: 16px;
  background-position: center;
  background-color: var(--gray-slightly-light);
  border: 1px solid var(--gray-light);
  border-radius: 8px;
  padding: 12px;
}
.CSVImporter_ProgressDisplay__progressBarIndicator {
  background-color: var(--green);
  width: 0;
  height: 8px;
  border-radius: 100px;
  transition: width var(--timing-function) 250ms;
}
